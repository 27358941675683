.accountAndAccessDialog .MuiDialog-paperScrollPaper {
  max-width: none !important;
}


.action-popup-container {
  display: inline-block;
}

.action-trigger {
  cursor: pointer;
}

.action-popup-content {
  padding: 10px 5px;
  width: 730px;
}

.dialog-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.DialogTitleContainer{
  font-size: var(--primary-font-size) !important;
  padding: 15px 15px !important;
  background-color: var(--pop-background-color) !important;
  border-bottom: 1px solid var(--pop-border-color) !important;
  font-weight: bold !important;
  cursor: move !important;
}

.close-icon {
  cursor: pointer;
  font-size: 16px;
  color: #888;
  padding: 3px;
  background-color: #f4f5f7;
}

.close-icon:hover {
  color: #333;
}

.iconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.iconBox:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.iconBox:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.iconBox svg {
  width: 14px;
  height: 14px;
  color: #333;
}
.updateIcon:hover svg {
  color: #2a9d8f;
}

.deleteIcon:hover svg {
  color: #e76f51;
}

.viewIcon:hover svg {
  color: #457b9d;
}

.manageClickRed {
  text-decoration: underline;
  color: red;
  cursor: pointer;
  transition: color 0.3s;
}
.manageClick {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  transition: color 0.3s;
}

.deactivateAccountContainer {
  background-color: rgb(246, 246, 247);
  padding: 10px;
  margin: 10px;
}
.PopUpCareGiverUserViewMainContainer {
  padding: 10px;
}
.PopUpAdminEmployeeViewMainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  padding: 10px;
}
.adminEmployeeViewMainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
.roleItem {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.roleItem input {
  margin-right: 8px;
}

.subMainContiner {
  border: 1px solid #cbcbcd;
  border-radius: 6px;
  padding: 15px;
}
.errorMessage {
  color: red;
  font-size: 13px;
  margin-left: 5px;
  display: block;
}
.roleSpamMsgConatiner {
  display: flex;
}

.backgroundcolorLightDark {
  background-color: rgb(246, 246, 247);
  padding: 10px;
}
