.profileMainContainer {
}

.profileTabsMenu {
  background-color: #fff !important;
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
}

.profileEachMenu {
  min-height: 45px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: none !important;
}

.profileEeachMenuIcon {
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 0px !important;
}

.Mui-selected {
  color: var(--primary-button-background-color) !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: var(--primary-button-background-color) !important;
}

.profileEachTabPanel {
  padding: 0 !important;
  margin-top: 5px;
}

.accountMainContainer {
  /* background-color: #fff; */
}

.securityMainContainer {
  padding: 10px 10px;
}
.clientSecurityMainContainer{
  
}

.changePasswordFormMainContainer {
  display: grid;
  row-gap: 15px;
  column-gap: 15px;
}

.currentPasswordFormMainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.formMainContainerForPopUp {
  display: grid;
  gap: 20px;
}

.newPasswordFormMainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.changePasswordInfoMainContainer {
  margin-top: 20px;
}

.changePasswordTitle {
  color: #666370 !important;
}

.changePasswordSubTitle {
  color: #666370 !important;
  font-size: 13px;
  margin-left: 15px;
}

.changePasswordButtonContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profilePhotoContainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.accountProfileImage {
  width: 200px;

  /* border: 1px solid #7367F0; */
  border-radius: 5px;
}

.accountProfileButtonContainer {
  display: flex;
  gap: 15px;
}

.accountProfileButtonText {
  margin-top: 10px;
  color: #666370 !important;
  font-size: 13px;
}
.formAndRolesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.docViewText {
  color: crimson;
}

.emailRequireMsg{
  margin-left: 10px;
}
