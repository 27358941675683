@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500&display=swap");

body {
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #edebe9;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: var(--primary-font-size) !important;
}
.MuiInputBase-input {
  font-size: var(--primary-font-size) !important;
}

.MuiMenuItem-root {
  font-size: var(--primary-font-size) !important;
}

.MuiSelect-select {
  padding: 6px 14px !important;
}
