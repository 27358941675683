.clientDoctorMainContaner {
  background-color: #fff !important;
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
  padding: 10px;
}

.clientDoctorCaseNoteInputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}



.clientDoctorTable {
  margin-top: 15px;
}
.changeLogBtnContainer{
  display: flex;
  align-items: center;
  gap: 10px;
}
.changeLogBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;

  font-size: var(--primary-font-size);
  gap: 5px;

  color: rgb(115, 103, 240);
  margin: 15px 5px 15px 5px;
}

.underlineClick {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  /* margin: 2px; */
  font-size: var(--primary-font-size);
  gap: 5px;
  cursor: pointer;
  color: var(--primary-button-background-color) !important;
}
.underlineClick:hover {
  border-bottom: 1px solid var(--primary-button-background-color) !important;
}
.underlineClickTitle {
  margin-top: 3px;
  /* text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.underlineClickBtnContainer {
  font-size: 13px;
  display: flex;
  justify-content: end;
  gap: 20px;
  align-items: center;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}
.ClientDocterMainConatiner {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.clientProfileMainContainer {
  padding: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 6px;
}
.clientProfileMainContainerWithoutShadowBox {
  padding: 20px;
  background-color: white;
}

.activeAddButtonContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.addButton {
  /* border-radius: 20px !important; */
}

.UCPUaction-popup-content {
  padding: 15px;
  width: 355px;
}
.UCNPUformInputContainer {
  width: 10px;
}
.CDCNunderlineClickBtnContainer {
  font-size: 13px;
  display: flex;
  justify-content: end;
  gap: 20px;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
}

.logMainContainer{
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
  background-color: #fff !important;
  padding: 10px;
}

.searchButtonContainer{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.leadSearchMainCotainer{
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  /* align-items: center; */
  background-color: #fff;
  border: 1px solid #d6d6d6 !important;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; */
  padding: 10px 25px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 6px;
  gap: 15px;
}

.fiterMainContainer{
  display: flex;
  align-items: center;
  gap: 10px;
}

.allFlterContainer{
  display: none;
}

.cocDropdownContainer{
  width: 300px;
}

.countyDropdownContainer{
  width: 300px;
}
.statusDropdownContainer{
  width: 300px;
}
.searchFilterBtnContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.downArrowIcon {
  transition: transform 0.3s ease;
}

.rotateArrow {
  transform: rotate(180deg);
}

.actionContainer{
  display: flex;
  justify-content: center;
  align-items: ce;
}