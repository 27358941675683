/* Reset default styles for printing */
@media print {
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
}
.A4Conatiner {
  border: 2px solid #000;
  max-width: 800px;
  margin: 0 auto;
}
/* Global styling for form elements */
.care-plan .label {
  font-weight: bold;
}

/* Container for the client care plan */
.care-plan {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.eachMainContainer {
  padding: 15px 0px;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.DietNallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.clientNameMrContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Checkbox and input styles */
.care-plan input[type="text"],
.care-plan input[type="checkbox"],


/* Center-align the heading */
.care-plan h2 {
  text-align: center;
}

/* Adjust checkbox alignment */
.care-plan input[type="checkbox"] {
  margin-right: 5px;
  transform: translateY(2px);
}

/* tables  */
/* table,
td,
th {
  border: 1px solid black;
  font-size: 14px;
}
table {
  border-collapse: collapse;
  width: 100%;
} */

/* .tableHeader {
  align-items: center;
  padding: 5px;
  /* padding: 4px 4px 4px 10px; 
  font-weight: bold;
} */

/* .tableItem {
  padding: 10px;
} */

.gridHeader {
  border: 1px solid #ccc;
  background-color: #bebebf;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 4px 4px 4px 10px;
  font-weight: bold;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
}
.grid-container2 {
  display: grid;
  grid-template-columns: 2fr 1fr; /* two columns */
}
.grid-item {
  padding: 10px;
  border: 1px solid #ccc;
}
.subData {
  display: flex;
}

/* signature Section */
.signatureSubSection {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}
.textInputSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.inputSection {
  border-bottom: 1px solid black;
}

/* Page layout for A4 size */
@media print {
  .care-plan {
    max-width: 210mm;
    margin: 20px auto;
    padding: 20px;
    page-break-after: always;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}
