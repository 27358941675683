/* CareGiverSecurity.css */

body {
  font-family: Arial, sans-serif;
}
.mainContainer {
  margin-bottom: 7px;

  border: 1px solid #ddd;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--pop-border-color) !important;

}
.CGSHeader {
  font-size: 14px;
  font-weight: bold;
  color: #5d5b69;
  margin-bottom: 10px;
}

.confirmationSection {
  font-size: 13px;
  color: #5d5b69;
}

.buttonContainer {
  text-align: center;
}
.DeactivateAccount {
  background-color: rgb(245, 30, 30);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.DeactivateAccount:hover {
  background-color: rgb(183, 26, 26); /* Change the background color on hover */
}

.fontSize13px {
  font-size: 13px;
}
