.ClientListMainContainer {
  margin-top: 5px;
  border: 1px solid var(--pop-border-color) !important;
  background-color: white;
  border-radius: 5px;
}

.headerContainer, .DocterInfoHeaderContainer {
  justify-content: space-between;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.headerContainer{
  color: var(--table-hearder-text-color);
  background-color: var(--table-header-backgrond-color) !important;
  grid-template-columns:  1fr 2fr 2fr 1fr;

}
.DocterInfoHeaderContainer {
  background-color: var(--table-header-backgrond-color) !important;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr;
}

.headerItem {
  font-size: 14px;
  color: var(--table-hearder-text-color);

}

.headerItemRight{
  text-align: right;
}
.noRecordsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: var(--primary-font-size);
  color: var(--primary-text-color);
}

.gridContainer, .DocterInfoGridContainer {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.gridContainer {

  grid-template-columns: 1fr 2fr 2fr 1fr;

}
.DocterInfoGridContainer {

  grid-template-columns: 1fr 3fr 2fr 2fr 2fr;

}
.gridItem {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #5d5b69;
}
.SESgridItem{
  padding-top: 10px;
  font-size: 13px;
  color: #5d5b69;
  text-align: right;

}
.gridItemNameEmail {
  font-size: 14px;
  color: #5d5b69;
  text-decoration: none !important;

  /* font-weight: bold; */
}
.gridItemName {
  text-decoration: none !important;
  color: #5d5b69;

}
.gridItemName:hover {
  cursor: pointer;
  color: #7367f0;
}
.gridItemEmail {
  font-size: 12px;
  color: #afacb5;
  font-weight: normal;
}
.avatarNameContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
.deleteIcon {
  cursor: pointer;
  color: rgb(239, 61, 61);
}
.flexLeft {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.actionMarginLeft{
  margin-left: 5px;
}
