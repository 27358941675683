.topNavMainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-image: linear-gradient(to right, #141e30, #243b55) !important; */
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  /* border-radius: 6px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.topNavLeftDiv {
  display: flex;
  align-items: center;
}

.topNavRightDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}
.topNavBarImgNameContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size:  var(--primary-font-size);
  color: var(--primary-text-color);
}
.topNavBarIcon {
  font-size: 20px;
}

.profileImage {
  width: 25px;
  border: 1px solid #7367f0;
  border-radius: 50%;
}

.hambugerIcon {
  font-size: 23px;
  cursor: pointer;
}

.bellIcon {
  font-size: 23px;
}

.profileDropDown {
  margin-top: 20px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.profilePopupContainer {
  display: flex;
  align-items: center;
  padding: 15px;
  min-width: 180px;
}

.profilePopupMainContainer {
}

.profilePopupTitleMainContainer {
  margin-left: 15px;
}

.profileNameText {
  font-weight: 500;
  color: #666370 !important;
}

.profileTypeText {
  font-size: 13px;
  color: #666370 !important;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.onlineIcon {
  color: var(--button-update-green-color);
  margin-right: 2px;
  margin-bottom: 2px;
  font-size: 12px;
}

.mennItemsPopupMainContainer {
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.profilePopupButtonsSelected {
  background: var(--primary-button-background-color) !important;
  color: #fff !important;
}

.profilePopupButtonsUnSelected {
  background-color: #fff !important;
  color: #666370 !important;
}

.profilePopupButtons {
  box-shadow: none !important;
  justify-content: flex-start !important;
}

.profilePopupButtonsUnSelected:hover {
  background-color: var(--text-hover-background-color) !important;
  color: var(--primary-button-background-color) !important;
}

.logoutButton {
  background-color: #fff !important;
  color: #666370 !important;
}
