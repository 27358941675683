.confirmPopupButonContainer {
  display: flex;
  gap: 16px;
}

.confirmPopUpIcon {
  color: #cfcf3e;
}
.dialogAction {
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 10px;
  margin: 10px;
}
.deleteButton {
  width: 100px !important;
  background: linear-gradient(to right, #d63031, #c0392b) !important;
  font-size: 16px !important;
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
  Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", Segoe UI Symbol !important;
}

.cancelButton {
  width: 100px !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
  Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", Segoe UI Symbol !important;
  background: linear-gradient(
    72.47deg,
    rgb(115, 103, 240) 22.16%,
    rgba(115, 103, 240, 0.7) 76.47%
  ) !important;
}
.borderBottom{
  border-bottom: 1px solid #ccc;
}

