@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid rgb(115, 103, 240);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

.loadingDilogeMainConatiner .MuiPaper-root {
  background-color: transparent !important;
  box-shadow: none !important;
  overflow-y: hidden;
  overflow-x: hidden;
}
