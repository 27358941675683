.ag-theme-alpine .ag-root-wrapper {
  border-radius: 0.3rem;
  border: 1px solid #d6d6d6 !important;
}

.ag-header-cell-label {
  color: #4b484c;
}

.tab-content {
  margin-top: 15px;
}

.ag-header {
  background-color: var(--table-header-backgrond-color) !important;
}

.ag-cell-focus,
.ag-cell-no-focus {
  outline: none;
  border: none !important;
}
.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-row-hover-color: #e6e6e6 !important;
  --ag-selected-row-background-color: var(--table-row-selected-background-color) !important;
}

.ag-theme-alpine .ag-row-selected {
  background-color: var(--table-row-selected-background-color) !important;
}

.ag-theme-alpine {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; */
  border-radius: 0.3rem;
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none;
}

.inner-ag-cell {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  cursor: text !important;
  max-width: 100%;
  font-size: 13px;
}

.ag-overlay-no-rows-center {
  display: none;
}

.ag-cell:focus {
  outline: none !important;
}

#messageText {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #595959;
  font-size: 0.8rem;
}

.innerAgCell {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  cursor: text !important;
  max-width: 100%;
}

.ag-inner-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  max-width: 100%;
}
.ag-inner-cell:hover {
  color: var(--primary-button-background-color);
}

.ag-header-cell.center-header .ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell-text{
  font-weight: 500 !important;
}

.ag-header-cell-resize::after{
background: #95a5c2 !important;
}

.ag-icon{
  color: var(--primary-button-background-color);
}
