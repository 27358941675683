.STDformInputContainer {
  padding: 10px 15px;
}

.STDBtnContainer {
  margin-top: 30px;
}

.STDupdateCancelContainer {
  display: flex;
  gap: 10px;
  margin-left: 15px;
}
.scheduleIndicators {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.expireDateIndicator,
.upcomingDataIndicator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.customSymbol {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 3px;
}

.expireSymbol {
  background-color: #999999;
}

.upcomingSymbol {
  background-color: var(--primary-button-background-color);
}
.scheduleIndicatorsTitle {
  color: #726e7a;
  font-size: 14px;
}

.event-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin-bottom: 2px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.expired-event {
  color: #686565;
  text-decoration: line-through;
  background-color: #e4e4e4 !important;
  border-radius: 3px;
}

.upcoming-event {
  color: white;
  border-radius: 3px;
}
.upcoming-event-caregiver {
  background-color: var(--primary-button-background-color) !important;
  color: white;
  border-radius: 3px;
}
.InitialVisit {
  background-color: #4198ff !important;
}
.QuaterlyVisit {
  background-color: #e614fd !important;
}
.AnuallVisit {
  background-color: #ff5e57 !important;
}
.ClientMoves {
  background-color: #3ac47d !important;
}
.CaregiverChanged {
  background-color: #6f42c1 !important;
}
.CareGiverInitialVisit {
  background-color: #aac142 !important;
}

.event-title {
  font-size: 13px;
}

.event-time {
  font-size: 13px;
}

.calenderInfoText {
  font-size: 13px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  color: #95939c !important;
}
