.accordionMainContainer {
  background-color: #fff !important;
  margin-bottom: 10px;
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
}

.accordionSummaryMainContainer {
  background-color: #fff !important;
  border-radius: 6px 6px 6px 6px !important;
  -webkit-flex-direction: none !important;
  flex-direction: row !important;
}

.accordionTitle {
  color: #666370 !important;
  font-weight: bold;
  font-size: 14px;
}

.formMainContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
  column-gap: 15px;
}
.formMainContainerForFour {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 0.3fr;
  row-gap: 15px;
  column-gap: 15px;
}

.formMainUniqueContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
  column-gap: 15px;
}

.fromTitleContainer {
  font-size: 13px;
  color: #666370;
}

.logContainer {
  padding: 10px;
}

.logbuttonContainer {
  size: 30px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.logFormContainer {
  background-color: #fff !important;
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important;
  border-radius: 6px;
}

.searchPdfContainer {
  background-color: #fff !important;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  align-items: center;
}
.searchBox {
  width: 500px;
}
.pdfFile {
  font-size: 20px;
  color: red;
}
.pdfButton {
  cursor: pointer;
}
