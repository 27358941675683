.flexContainer {
  display: flex;
  gap: 10px;
}
.actionButonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.addCareGiverButton {
  width: auto !important;
}
.searchMainCotainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--pop-border-color) !important;
  padding: 10px 25px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 6px;
}

.searchBar div {
  border-radius: 28px;
}
.searchContainer {
  width: 40%;
}

.marginTop {
  margin-top: 10px;
}

.addressMainContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  column-gap: 15px;
}

.emergencyContactTitle {
  margin-top: 30px;
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.fixedWidth {
  width: auto !important;
}

.confidentailMainContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  column-gap: 15px;
}

.headingTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

.docviewMainContainer {
  display: flex;
  gap: 10px;
}

.listMainDivContainer {
  width: 100%;
  background-color: #fff;
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
  height: 75vh;
  overflow: scroll;
}
.clientDocviewMainContainer {
  width: 100%;
  background-color: #fff;

  height: 75vh;
  overflow: scroll;
}

.viewMianDivContainer {
  width: 70%;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(47, 43, 6, 0.14), 0 0 transparent, 0 0 transparent;
}
.docDetailsMainContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.docSubTypeTitle {
  margin-left: 10px;
}

.docTypeTitle {
  color: #666370;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.docDetailsContainer {
  border-radius: 6px;
  padding: 5px 15px;
  border: 1px solid #cbcbcd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.posDocDetailsContainer {
  border-radius: 6px;
  padding: 5px 15px;
  border: 1px solid #cbcbcd;
  align-items: center;
}
.docTitleToViewConatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notApproved {
  border: 1px solid var(--button-delete-red-color);
}

.docTitle {
  font-size: 13px;
  font-weight: bold;
  color: var(--primary-button-background-color);
}
.docCaseNote {
  font-size: 14px;
  color: #333;
  padding: 8px 12px;
  border-left: 4px solid #918f98;
  background-color: #f8f9fa;
  margin: 10px 0;
  border-radius: 4px;
  font-style: italic;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notApprovedDocTitle {
  color: var(--button-delete-red-color);
}

.docSubTitlemainContainer {
  display: flex;
  gap: 15px;
  font-size: 13px;
}
.docDownloadText {
  cursor: pointer;
}

.docDownloadText:hover {
  text-decoration: underline;
  color: yellowgreen;
}

.docDeleteText {
  cursor: pointer;
}

.docDeleteText:hover {
  text-decoration: underline;
  color: red;
}

.redDelete {
  text-decoration: underline;
  color: red;
}

.docViewText {
  font-size: 13px;
  color: #666370;
  cursor: pointer;
}

.docViewText:hover {
  text-decoration: underline;
  color: blue;
}
.docDivTitle {
  background: linear-gradient(
    72.47deg,
    rgb(115, 103, 240) 22.16%,
    rgba(115, 103, 240, 0.7) 76.47%
  ) !important;
  color: #fff !important;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
}

.docAddContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.docAddIcon {
  font-size: 20px;
}

.docAddContainer:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(115, 103, 240);
}

.fileUploadMainContainer {
  width: 355px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.enterCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
}

.nursingAssessmentButtonContainer {
  margin: 10px;
  color: #7367f0;
  display: grid;
  justify-content: flex-end;
}
.ClientListContainer {
  padding: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.DoctorListContainer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
.clientListinputFiled {
  width: 400px;
}
.addClientBtn {
  margin-top: -5px;
  margin-right: 15px;
}

.DateFromToConatiner {
  display: flex;
  align-items: center;
  gap: 10px;
}
.DialogActionsContainer {
  background-color: var(--pop-background-color);
  border-top: 1px solid var(--pop-border-color);
}
.signatureMain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signatureFormContainer {
  border: 1px solid #ddd;
  padding: 20px;
  width: 70%;

  text-align: center;
  border-radius: 10px;
}
.signatureTitleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.signatureIconTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.subSignatureFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signatureTitle {
  color: var(--primary-text-color);
  /* font-weight: bold; */
  font-family: var(--font-family);
  font-size: var(--primary-font-size);
}
.signatureIconCircle {
  border-radius: 100px;
  padding: 10px 5px 10px 13px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: var(--primary-button-background-color);
}

.signatureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  border: 1px solid #ddd;
  width: 500px;
  height: 300px;
  border: 1px solid #ddd;
  background-color: #e7e7e7;
  cursor: pointer;
}

.signatureImage {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signatureLabel {
  font-size: 16px;
  color: #666;
}

.signatureUploadButton {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
}

.signatureUploadButton:hover {
  background-color: #0056b3;
}

.signatureSubmitButton {
  margin-top: 25px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
}

.signatureSubmitButton:hover {
  background-color: #218838;
}

.signatureDivider {
  margin-top: 25px;
  margin-bottom: 250px;
  border-bottom: 2px solid #007bff;
}

.clockInOutContainer{
  background-color: #fff !important;
  border: 1px solid var(--pop-border-color) !important;
  padding: 10px;
  border-radius: 5px;
}

.dovViewerDateContainer{
  color: var(--primary-text-color);
}


.docDetailsLeftContainer{
  display: flex;
  align-items: center;
  gap: 10px;
}

.docIconContainer{
  background-color: var(--primary-button-background-color);
  color: #fff;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  font-size: 20px;
}