/* Reset default styles for printing */
@media print {
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
}
.A4Conatiner {
  border: 1px solid #000;
  max-width: 800px;
  margin: 0 auto;
  min-height: 842px;
  background-color: #f9f9f9;
}
/* Global styling for form elements */
.ClientDocterInfo .label {
  font-weight: bold;
}

/* Container for the client care plan */
.ClientDocterInfo {
  font-family: "Calibri", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}
.CDIMainHeader {
  font-size: 21.33px;
  font-family: "Cambria", sans-serif;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center; /* Center-align the text */
  display: flex;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  height: 60px; /* Set a fixed height for centering vertically */
}

.CDImainTableContainer {
  border-width: 1px 1px 0 1px; /* top right bottom left */
  border-style: solid;
  border-color: #000;
  margin: 10px;
}

.CDIGridConatiner {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  border-bottom: 1px solid #000;
}

.CDItitleSection {
  border-right: 1px solid #000;

  padding: 10px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.CDIresultSection {
  padding: 10px;
  color: #333;
  font-size: 16px;
}
