.logoMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 20px;
}

.logoTitleContainer {
  color: #5d5a68;
  font-weight: bold;
  font-size: 22px;
}

.logoImage {
  width: 179px;
}

.menuItemButton {
  font-size: 13px;
  justify-content: flex-start !important;
}

.menuButton {
  justify-content: space-between !important;
  background-color: #f8f8f9 !important;
  color: #666370 !important;
  box-shadow: none !important;
}

.menuDropDown {
  margin-top: 5px;
}

.marginGap {
  margin-top: 5px !important;
}

.capitalized-text {
  text-transform: capitalize;
}

.menuItemsTitle {
  color: rgba(47, 43, 61, 0.42);
  font-size: 13px;
}

.menuItemsTextContainer {
  display: flex;
  align-items: center;
}

.menuButtonIcon {
  margin-right: 5px;
}

.iconSize {
  font-size: 16px;
}

.menuItemsMainContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: scroll;
  height: 60vh;
  padding-right: 8px;
}

.menuItemsMainContainer::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  -webkit-box-shadow: none;
  border-radius: 6px;
}

.menuItemsSubTilte {
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  padding: 25px 25px 5px 15px;
  margin-bottom: 5px;
  position: relative;
  color: var(--secondary-nav-text-color);
}

.navleftBarLink {
  text-decoration: none;
}
.leftNavBarButtonUnselected {
  color: var(--primary-nav-text-color);
}

.leftNavBarButtonSelected {
  background-color: var(--Selected-color);
  border-left: 3px solid var(--primary-button-background-color);
  color: #fff;
}

.leftNavBarButtonUnselected,
.leftNavBarButtonSelected {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--primary-font-size);
}

.leftNavBarButtonUnselected:hover,
.leftNavBarButtonSelected:hover {
  color: var(--nav-text-hover-color);
}

.leftNavBarIcon {
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 2px;
  margin-left: 5px;
}

.dividerNavbar {
  border-color: var(--primary-text-color) !important;
  margin-top: 10px !important;
}
