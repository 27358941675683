.icon {
  cursor: pointer;
}
.container {
  margin: 0px 10px 0px 10px;
  max-width: 350px;
}
.errors {
  color: red;
  cursor: pointer;
}
.headtitle {
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 18px;
  color: #2f2b3dc7;
}
.signInText {
  font-size: 15px;
  color: #918f98;
}
.loginImage {
  height: 80vh;
  width: 70vw;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  padding: 20px 10px 20px 10px;
  background-color: #f8f7fa;
  object-fit: contain;
}
.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 0px 20px 0px 20px;
}

@media screen and (max-width: 1150px) {
  .loginImage {
    display: none;
  }
  .container {
    max-width: 450px;
    margin: 0px;
    padding: 35px 32px 35px 32px;
    margin: 0px 15px 0px 15px;
  }
  .login {
    margin: 0px;
    align-content: center;
    align-self: center;
    height: auto;
  }
}
.head {
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
}
.healthLogo {
  object-fit: cover;
  border-radius: 50px;
  width: 100%;
}

.forget {
  letter-spacing: 0.3px;
  color: var(--primary-button-background-color);
  display: inline;
  float: right;
  cursor: pointer;
  text-decoration: none;
}

.backToLogin {
  color: var(--primary-button-background-color);
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-weight: lighter;
  cursor: pointer;
  text-decoration: none;
}

.forgetText {
  font-size: 14px;
  color: #918f98;
  line-height: 1.5;
  font-weight: 200;
  letter-spacing: 0.2px;
  word-spacing: 1px;
}

.resetCodeContainer {
  padding: 25px 32px;
}
.resetText {
  font-size: 14px;
  word-spacing: 1px;
  line-height: 1.5;
  font-weight: 200;
  letter-spacing: 0.2px;
  color: #fa667f;
}

.forgetTitle{
  
  font-size: var(--primary-font-size);
}
