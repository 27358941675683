.calendarContainer {
  border: 1px solid var(--pop-border-color) !important;
  border-radius: 6px;
  background-color: #fff;
}

.custom-buttons {
  width: 150px !important;
  padding: 4px 18px !important;
  border-radius: 17px !important;
  background-color: white !important;
  border: 1px solid var(--primary-button-background-color) !important;
  color: var(--primary-button-background-color) !important;
  box-shadow: none !important;
}

.custom-buttons:hover{
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.calendarSection {
  display: flex;
  /* grid-template-columns: 1fr 3fr; */

  /* gap:1rem; */
}

.leftCalendar {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.smallCalender {
  border-bottom: 1px solid #ccc;
}

/* for small calander */
.css-9reuh9-MuiPickersArrowSwitcher-root {
  gap: 7px !important;
}
.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  background-color: rgb(245, 245, 244) !important;

  padding: 3px !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected {
  /* background-color: rgb(115, 103, 240) !important; */

  background-color: var(--primary-button-background-color) !important;
  color: white !important;
}
.css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  /* background-color: rgb(115, 103, 240) !important; */
  background-color: var(--primary-button-background-color) !important;

  color: white !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  /* background-color: rgb(115, 103, 240) !important; */
  background-color: var(--primary-button-background-color) !important;

  color: white !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid var(--primary-button-background-color) !important;
  /* background-color: var(--primary-button-background-color) !important; */

  color: rgb(115, 103, 240) !important;
}

.addScheduleBtn {
  padding: 23.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  /* border-right: 1px solid #ccc; */
}

.rightCalendar {
  width: 100%;
  /* padding-top: 20px;
  padding-right: 20px; */
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px;
  border-left: 1px solid #ccc;
  padding: 22px;
}
.STDmainContainer {
  /* padding: 25px; */
}
.draweHeader {
  /* padding: 15px; */
  text-align: center;
}

.react-calendar button {
  margin: 0;
  outline: none;
}

/* .react-calendar {
  width: 300px !important;
  max-width: 100%;
  background: white;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  line-height: 1.125em;
} */

/* .fc-calendar-event{
background-color: red;
width: 900px;
} */

.STDheaderAndCloseicon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: var(--pop-background-color) !important;
  border-bottom: 1px solid var(--pop-border-color) !important;

}

.closeIcon {
  cursor: pointer;
  font-size: 20px !important;
  color: #555;
  background-color: #e5e5e7;
  padding: 5px;
  border-radius: 5px;
}

.closeIcon:hover {
  color: #000; /* Change color on hover if desired */
}
