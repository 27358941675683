.fc-prev-button,
.fc-next-button {
  /* display: none !important; */
  background-color: #fff !important;
  color: var(--primary-button-background-color) !important;
  border-color: #fff !important;
}

.fc-button:hover {
  box-shadow: none !important;
}

.fc-button:focus {
  box-shadow: none !important;
}

.fc-dayGridMonth-button,
.fc-timeGridDay-button,
.fc-listMonth-button {
  background-color: #d6e9ff !important;
  /* border-color: #e8e7fd !important; */
  border-color: #e4f0ff !important;
  /* color: #7f74f1 !important; */
  color: var(--primary-button-background-color) !important;

  padding: 4px 18px !important;
  font-weight: bold !important;
  font-size: 13px !important;
    border-color: var(--primary-button-background-color) !important;

}

.fc-dayGridMonth-button {
  border-right-color: transparent !important;
}

.fc-listMonth-button {
  border-left-color: transparent !important;
}

.fc-dayGridMonth-button:hover,
.fc-timeGridDay-button:hover,
.fc-listMonth-button:hover{
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;

}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  margin-left: 0px !important;
}

.fc-toolbar-title {
  font-size: 18px !important;
  color: #656270 !important;
  font-weight: 500 !important;
}

.fc-col-header-cell-cushion {
  font-size: 13px !important;
  padding: 7px !important;
}

.fc-toolbar-title {
  margin-inline-start: 0.25rem;
}

.fc-col-header-cell-cushion {
  font-weight: normal !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f7f7f7 !important;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  color: #656270 !important;
}

.fc-daygrid-event-harness {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.fc-daygrid-dot-event:hover {
  background: none !important;
}

.fc-dayGridMonth-view .fc-daygrid-day-frame {
  min-height: 125px !important;
}
.fc-daygrid-day-number,
.fc-daygrid-more-link,
.fc-col-header-cell-cushion,
.fc-popover-title {
  color: #726e7a;
}
.fc-v-event {
  background-color: #fff !important;
  border: none !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff !important;
}
.fc-list-day-cushion,
.fc-list-event-time {
  color: #726e7a !important;
  font-weight: normal;
}
