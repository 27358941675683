.pageMainContaianer {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(47, 43, 6, .14), 0 0 transparent, 0 0 transparent;  
  border-radius: 6px;
  padding: 15px;
}

.dashboardGridContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-bottom: 10px;
}

.dashboardGridScheduleNotificationContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 10px;
}
.dashboardChartContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.dashboardFullWidthContainer{
  margin-bottom: 10px;
}
.EmployeeChartMainContainer {
  background: #fff;
  padding: 22px 22px 0px 22px;
  border-radius: 8px;
  border: 1px solid var(--pop-border-color) !important;
  font-family: var(--font-family);
}
.EmployeeChartMainContainer:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.EmployeeChartMainContainerMini {
  background: #fff;
  padding: 22px ;
  border-radius: 8px;
  border: 1px solid var(--pop-border-color) !important;
  font-family: var(--font-family);
}
.EmployeeChartMainContainerMini:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer .title {
  font-size: var(--primary-font-size);
  font-weight: bold;
  color: var(--primary-text-color);
}

.titleContainer .total {
  color: var(--primary-text-color);
  font-size: 13px;
}

.statsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.InfoboxRadialBarContainer{
  display: flex;
  align-items: center;
}
.statsContainerEmpChart{
  width: 75%;
}
.statItemEmpChart {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.pieChartDiv {
  width: 200px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detailContainerEmpChart {
  display: flex;
  justify-content: space-between;
}

.subDetailContainer {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.subDetailContainerPieChart {
  padding: 5px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid #66637042;
  box-shadow: 0 1px 0px rgba(47, 43, 6, 0.14);
}

.statItem {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.statIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.statItemIconTitleDiv {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 25%;
  gap:5px;
}

.statIcon.purple {
  background-color: #e0d4ff;
}

.statIcon.blue {
  background-color: #d4f1ff;
}

.statIcon.red {
  background-color: #ffd4d4;
}

.statIcon.green {
  background-color: #d4ffd4;
}

.statTitle {
  font-size: var(--primary-font-size);
  color: var(--primary-text-color);
  text-align: center;
}

.detailTitleActive {
  color: var(--chart-positive-color);
  font-family: var(--font-family);
  font-size: 13px;
  margin-right: 5px;
}

.detailTitleNotWorking {
  color: var(--chart-negative-color);
  font-size: 13px;
  margin-right: 5px;
}

.detailTitleTotal {
  color: var(--primary-text-color);
  font-size: 13px;
  margin-right: 5px;
}

.detailValueTotal, .detailValueActive, .detailValueNotWorking {
  color: var(--primary-text-color);
  font-size: 13px;
}

.detailValueTotal {
  font-size: 13px;
}


.detailContainerLeadsChart {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subDetailContainerLeadsChart {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
  font-size: 1rem;
  color: #555;
}

.value {
  font-size: 1rem;
  font-weight: bold;
  color: #333;

}

.notificationContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--pop-border-color) !important;
  transition: box-shadow 0.3s ease;
}

.notificationContainer:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



.notificationList {
  display: flex;
  flex-direction: column;
}

.notificationItem {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.notificationItemText {
  font-size: var(--primary-font-size);
  color: #555;
}

.notificationItemTime {
  font-size: 0.9em;
  color: #aaa;
}

.notificationHeaderContainer {
  display: grid;
  grid-template-columns: 2fr 1fr !important;
}

.notificationIcon{
  font-size: 16px;
  color: var(--button-delete-red-color);
}

.wellcomeWidgetleft{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  gap: 30px;
}

.wellcomeWidgetRight{
  display: flex;
  justify-content: center;
  align-items: center;
}

.wellcomeSubTitle{
}

.wellcomeWidgetContainer{
  display: flex;
  background: var(--primary-button-background-color);
  padding: 22px;;
  padding: 22px ;
  border-radius: 8px;
  border: 1px solid var(--pop-border-color) !important;
  font-family: var(--font-family);
  justify-content: space-between;
}
.wellcomeWidgetTitle{
  font-size: 24px;
  color: white;
}

.wellcomeWidgetSubTitle1{
  font-size: 16px;
  color: white;

}

.wellcomeSubTitle2{
  font-size: 13px;
  color: white;
}

.smileIcon{
  font-size: 70px;
  color: #e9c912;
}

.scheculeNotificationContainer{
  padding: 5px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.scheduleNotificationTitle{
  display: flex;
  align-items: center;
  font-size: 16px;
  color:var(--primary-button-background-color);
}

.scheduleNotificationSubTitle{
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--primary-text-color);;
}

.wellComeWidgetTitlesContainer{
  display: flex;
  flex-direction: column;
}

.clientWidgetContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.clienWidgetTitle{
  font-size: var(--primary-font-size);
  font-weight: bold;
  color: var(--primary-text-color);
}

.clientWidgetSubTitle{
  font-size: 16px;
  background-color: var(--primary-button-background-color);;
  padding: 2px 10px;
  color: #fff;
  border-radius: 10%;
}

.dashboardNotificaionContainer{
  min-height: 150px;
}