.manualNotificationBtnContainer{
    display: flex;
    align-items: center;
  }

  .SEMButtonContainer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 20px;

  }
  .switchLabel {
    font-size: var(--primary-font-size); 
    align-items: center;
    display: flex; 
  }

  .spamError{
    color: #ff0000;
  }
  .ESNDheaderContainer{
    justify-content: space-between;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  .ESNDgridContainer{
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .notificationDetails {
    margin-bottom: 20px;
  }
  
  .notificationTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .notificationDescription {
    font-size: 16px;
  }
  