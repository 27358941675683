.custom-button {
  background: var(--primary-button-background-color) !important;
  color: #fff !important;
  font-size: 16px !important;
  text-transform: none !important;
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol !important;
}

.capitalized-text {
  text-transform: capitalize;
  font-size: var(--primary-font-size);
}

.disableButton{
  background-color: var(--button-disable-fadedBlack-color) !important;
  color: #fff !important;
}

.roundedButton{
  padding: 4px 18px !important;
  border-radius: 17px !important;
  background-color: white !important;
  border: 1px solid var(--primary-button-background-color) !important;
  color: var(--primary-button-background-color) !important;
  box-shadow: none !important;
}

.roundedButton:hover{
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.boderRoundedButton{
  display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    font-size: var(--primary-font-size);
    gap: 5px;
    cursor: pointer;
    /* color: var(--primary-button-background-color) !important; */
    border: 1px solid var(--pop-border-color);
    border-radius: 15px;
    padding: 2.5px 10px;
    background-color:var(--rounded-button-background-color)  ;
    
}

.boderRoundedButton:hover{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
  rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.boderRoundedButtonText{
}
.boderRoundedButtonIcon{
  color: var(--primary-button-background-color) !important; 
}

.boderRoundedButtonIconGreen{
 color: var(--button-update-green-color);
}

.boderRoundedButtonIconRed{
  color: var(--button-delete-red-color);
}

.boderRoundedButtonInline{
  display: inline;
}

.borderRoundedButtonText{
  margin-left: 5px;
}