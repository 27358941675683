.medicationContainer {
  font-size: 14px;
  display: flex;
  justify-content: end;
  gap: 20px;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
}

.homeSafetyWrapper {
  padding: 5px;
  margin: 10px;
  color: #666370;
  font-size: 14px;
}

.medicationWrapper {
  padding: 5px;
  margin: 10px;
  /* margin: 16px 10px 16px 10px; */
  color: #666370;
  font-size: 14px;
}
.medicationSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.heading {
  text-align: center;
  border-bottom: 2px solid #666370;
  font-size: 17px;
  letter-spacing: 0.5px;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.row {
  display: flex;
  gap: 15px;
  align-items: center;
}
.rowSection {
  display: flex;
  gap: 8px;
  align-items: center;
}
.inputBorder {
  border: none;
  border-bottom: 1px solid #666370;
  outline: none;
  color: #666370;
  font-size: 14px;
}
.inputBorder:focus {
  color: #666370;
  font-size: 14px;
}

.inputMiddleWidth {
  width: 350px;
}
.inputLessWidth {
  width: 150px;
}
.fullWidth {
  width: 500px;
}

.tableHead {
  display: flex;
  align-items: center;
}
.headersItem {
  border: 0.5px solid #666370;
  padding: 10px 25px;
}

.visitType {
  display: flex;

  justify-content: flex-end;
}

.tablecell {
  border-right: 0.5px solid #c4c4c4;
}
.table {
  border: 0.5px solid #c4c4c4;
}
.homeSafetyContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.clientListinputFileds {
  display: flex;
  width: 500px;
}
.safetySelectInput {
  display: flex;
  gap: 10px;
  align-items: center;
}

.nameList {
  width: 130px;
}
.nameDesc {
  width: 200px;
}
.medicationForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.formButtonSave {
  margin-top: 25px;
}

.MuiTableCell-root {
  padding: 10px;
  font-weight: bold;
}
.MuiTableCell-body {
  font-weight: 500;
}

.textForm {
  display: flex;
  gap: 10px;
}

.radioButonForm {
  display: flex;
  gap: 5px;
  align-items: center;
}

.subjectMatter {
  margin-top: 10px;
}
