.agencyMainContainer {
  display: flex;
  /* background-color: #f7f6f9; */
  background-color: var(--secondary-background-color);

  /* #d4d8ff */
}

.agencgyLeftContainer {
  /* background-color: #fff; */
  background-color: var(--primary-background-color);
  /* background-image: var(--primary-background-color); */

  width: 300px;
  height: 100vh;

  transition: transform 0.1s ease-in;
  transform: translateX(0);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.agencyRightcontainer {
  height: 100vh;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.paagesMainContainer {
}

.agencyTopNavContainer {
  /* padding: 10px 20px; */
  position: sticky;
  top: 0;
  backdrop-filter: blur(5px);
  z-index: 50;
}

.agencyBodyContainer {
  padding: 10px 20px;
  line-height: 1.5;
}

.accountPersonalDetailForm {
  margin-top: 10px;
}

.accountProfileSaveButtonContainer {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}
.alertPopup {
}

.pageHeaderContainer {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageHeaderIconDetail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pageHeaderIconContainer {
  display: flex;
  align-items: center;
  background-color: var(--primary-button-background-color);
  /* background-color: var(--third-background-color); */

  padding: 10px;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
}

.pageHeaderTitleCategory {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pageHeaderTitle {
  font-size: var(--secondary-font-size);
}

.pageHeaderSubTitle {
  color: var(--primary-text-color);
  font-size: var(--primary-font-size);
}

/* button  */
.buttonUnselected {
  background-color: var(--button-cancel-fadedBlack-color) !important;
  color: #666370 !important;
  box-shadow: none !important;
}

.buttonSelected {
  background-color: var(--primary-button-background-color) !important;

  color: #fff !important;
}
.buttonSelectedRed {
  background: var(--button-delete-red-color) !important;
  color: #fff !important;
}

.buttonSelectedGreen {
  background: var(--button-update-green-color) !important;
  color: #fff !important;
}

.notificatioinBadge{
  background-color: var(--button-delete-red-color) !important;
}