:root {
  --primary-background-color: #273444;
  /* --primary-background-color: linear-gradient(to right, #141e30, #243b55); */
  --secondary-background-color: #f3f6fb;

  --primary-button-background-color: #4198ff;
  --button-delete-red-color: rgb(240, 103, 103);
  --button-update-green-color: #3ac47d;
  --button-cancel-fadedBlack-color: #dadada;
  --button-disable-fadedBlack-color: #aaa6a6;
  --chart-positive-color:#28C66E;
  --chart-negative-color:#ff3c2a;

  /* --Selected-color: #1d2430; */
  --Selected-color: #1c2530;
  --primary-text-color: #666370;
  --primary-nav-text-color: #b7c0cd;
  --secondary-nav-text-color: #f1f7ff;
  --nav-text-hover-color: #fff;
  --text-hover-background-color: #d6e9ff;

  --pop-background-color: #f4f5f7;
  --pop-border-color:#d6d6d6;

  --font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol;
  --primary-font-size: 13px;
  --secondary-font-size: 16px;

  --table-header-backgrond-color:#d3e7ff;
  --table-hearder-text-color:#4b484c;
  --table-row-selected-background-color:#DFF0D8;

  --rounded-button-background-color:#FAFBFB;
}
