.successMainContainer{
    width: 100% !important;
    background-color: rgb(255 255 199) !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    border-left: 3px solid var(--button-update-green-color);
    padding: 0px 12px !important;
}

.errorMainContaner{
    width: 100% !important;
    background-color: rgb(255 255 199) !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    border-left: 3px solid var(--button-delete-red-color);
    padding: 0px 12px !important;
}

.alertTextContainer{
    display: flex;
    flex-direction: column;
}

.alertTitle{
    font-size: 13px;
    color: var(--button-update-green-color);
}

.errorTitle{
    font-size: 13px;
    color: var(--button-delete-red-color);
}


.alertMessage{
    font-size: 13px;
}

.successMainContainer .MuiAlert-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--button-update-green-color) !important;
}

.errorMainContaner .MuiAlert-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--button-delete-red-color) !important;
}