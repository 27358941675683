.error {
  /* height: 3px; */
  font-size: 12px;
  word-spacing: 2px;
  letter-spacing: 0.5px;
  padding-top: 6px;
  color: red;
}

.title {
  padding-bottom: 6px;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: var(--select-options);
}
.input {
  letter-spacing: 0.009375px;
}
 .radioTitle {
  font-size: 13px;
  letter-spacing: 0.5px;
  color: var(--select-options);
}

.MuiFormControlLabel-label {
  font-size: 13px !important;
}
.Mui-checked.MuiRadio-root {
  color: #7367f0 !important;
  transform: scale(0.9);
}

#up---tohere :root {
  --select-options: #918f98;
}

.select-options div {
  color: var(--select-options) !important;
  letter-spacing: 0.5px;
  font-size: 15px;
}
.iconMenu {
  color: #918f98;
  cursor: pointer;
  padding-right: 10px;
}

.customPopper {
  height: 200px;
  overflow: hidden;
}

.MuiAutocomplete-popper {
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MuiAutocomplete-paper{
  font-size: 13px !important;

}