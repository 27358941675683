body {
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.pageNotFoundMainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px; /* Reduce padding for smaller screens */
  background-color: #fff;
  text-align: center;
  padding: 20px;
}

.titleMain {
  font-size: 34px; /* Reduce font size for smaller screens */
  color: #777;
  font-weight: 1px;
  margin-bottom: 5px; /* Reduce margin for smaller screens */
}

.titleMainSub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #777;
  font-size: 24px;
  /* text-align: center; */
  align-items: center;
}

p {
  font-size: 16px; /* Reduce font size for smaller screens */
  color: #777;

  margin-bottom: 20px; /* Reduce margin for smaller screens */
}

.ButtonDiv {
}

.errorImage {
  height: auto; /* Allow the image height to adjust based on content */
  max-width: 90%; /* Ensure the image doesn't overflow its container */
}

.pageNOtFoundImageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
